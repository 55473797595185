import axiosUtils from "../Services/Requests/AxiosUtils";
import axios from "../Services/Requests/AxiosConfig";
import permisos   from "../permisos";

export default{
    listar(filtros){
        return new Promise ((resolve, reject) => {
            let params = {params: axiosUtils.getParams(filtros)};
            axios.get("perfiles/listar", params).then(respuesta => {
                let data = respuesta.data;
                if(data.codigo !== 200)
                    throw data.mensaje;
                resolve(data.datos);
            }).catch(error => axiosUtils.axiosCatch(error, err => reject(err)));
        });
    },
    agregar(datos){
        return new Promise ((resolve, reject) => {
            let params = axiosUtils.getPostParams(datos);
            axios.post("perfiles/agregar", params).then(respuesta => {
                let data = respuesta.data;
                if(data.codigo !== 200)
                    throw data.mensaje;
                resolve(data);
            }).catch(error => axiosUtils.axiosCatch(error, err => reject(err)));
        });   
    },
    obtenerDetalle(filtros){
        return new Promise ((resolve, reject) => {
            let params = {params: axiosUtils.getParams(filtros)};
            axios.get("perfiles/obtenerDetalle", params).then(respuesta => {
                let data = respuesta.data;
                if(data.codigo !== 200)
                    throw data.mensaje;
                resolve(data.datos);
            }).catch(error => axiosUtils.axiosCatch(error, err => reject(err)));
        });
    },
    eliminar(filtros){
        return new Promise((resolve,reject)=>{
            let params = axiosUtils.getPostParams(filtros);
            axios.post("perfiles/eliminar", params).then(respuesta => {
                let data = respuesta.data;
                if(data.codigo !==200)
                    throw data.mensaje;
                resolve(data);
            }).catch(error=> axiosUtils.axiosCatch(error, err=>reject(err)));
        });
    },
    editar(datos){
        return new Promise((resolve,reject)=>{
            let params = axiosUtils.getPostParams(datos);
            axios.post("perfiles/editar",params).then(respuesta=>{
                let data = respuesta.data;
                if(data.codigo!==200)
                    throw data.mensaje;
                resolve(data);
            }).catch(error => axiosUtils.axiosCatch(error, err=>reject(err)));
        });
    },
    obtenerPermisos(){
        return new Promise((resolve, reject) => {
            let params = {params: axiosUtils.getParams()};
            axios.get("permisos/listar", params).then(resp => {
              let data = resp.data;
              if (data.codigo !== 200)
                throw data.mensaje;
              resolve(data.datos);
            }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
          });
    }, 
    editarPermisos(datos){
        return new Promise((resolve,reject)=>{
            let params = axiosUtils.getPostParams(datos);
            axios.post("perfiles/editarPermisos",params).then(respuesta=>{
                let data = respuesta.data;
                if(data.codigo!==200)
                    throw data.mensaje;
                resolve(data);
            }).catch(error => axiosUtils.axiosCatch(error, err=>reject(err)));
        });
    },
    obtenerPermisoPerfil(filtros){
        return new Promise ((resolve, reject) => {
            let params = {params: axiosUtils.getParams(filtros)};
            axios.get("perfiles/obtenerPermisoPerfil", params).then(respuesta => {
                let data = respuesta.data;
                if(data.codigo !== 200)
                    throw data.mensaje;
                resolve(data.datos);
            }).catch(error => axiosUtils.axiosCatch(error, err => reject(err)));
        });
    }

}
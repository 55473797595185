import PerfilRepo from "../Repositories/PerfilRepo";

export default class PerfilService{
    listar(filtros){
        return PerfilRepo.listar(filtros);
    }
    agregar(datos){
        return PerfilRepo.agregar(datos);
    }
    obtenerDetalle(filtros){
        return PerfilRepo.obtenerDetalle(filtros);
    }
    eliminar(filtros){
        return PerfilRepo.eliminar(filtros);
    }
    editar(datos){
        return PerfilRepo.editar(datos);
    }
    editarPermisos(datos){
        return PerfilRepo.editarPermisos(datos);
    }
    obtenerPermisos(){
        return PerfilRepo.obtenerPermisos();
    }
    obtenerPermisoPerfil(filtros){
        return PerfilRepo.obtenerPermisoPerfil(filtros);
    }
}
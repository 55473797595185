<template>
  <q-page>
    <!-- COMIENZA HEADER GLOBAL -->
    <div class="w100p bg-gris-panel q-px-lg q-py-sm row q-mb-md">
      <div class="col-8">
        <div class="titulo-header">Perfiles</div>
        <q-breadcrumbs gutter="xs" class="mt-minus-2" active-color="grey-system">
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <q-breadcrumbs-el :key="i" :label="breadcrumb.name" :to="breadcrumb.to" :class="{'text-template': (i+1)===breadcrumbs.length}"/>
          </template>
        </q-breadcrumbs>
      </div>
      <div class=" col-4 text-right row justify-end items-center">
        <q-btn color="primary" label="Alta de perfil" @click="abrirModalAlta()"></q-btn>
      </div>
    </div>
    <!-- FINALIZA HEADER GLOBAL -->
    <!-- COMIENZA BODY GLOBAL -->
    <q-scroll-area class="h100-125">
      <q-card class="w100p q-px-xl" flat>
        <!-- COMIENZA SECCION DE INPUT Y LOS BOTONES -->
        <q-card-section class="row filtros-section">
          <q-input class="w20p" dense outlined placeholder="Buscar palabra clave" ref="buscar"
                   :class="{'q-pb-xs q-mb-md' : showFiltros}"
                   v-model="filtros.busqueda" @keyup.enter.prevent="listar">
            <template #append>
              <q-icon name="las la-search" class="cursor-pointer" @click="listar"/>
            </template>
          </q-input>
          <div class="w80p q-pl-md row items-start">
            <q-btn @click="limpiar()" color="gris-panel" icon="las la-sync" label="Limpiar" unelevated
                   class="q-mr-md e-border-1" text-color="grey-system"/>
            <q-btn @click="cargarFiltros()" v-if="!showFiltros" class="e-border-1" color="gris-panel"
                   icon="las la-filter" text-color="grey-system" label="Filtros" unelevated/>
            <!-- COMIENZA SECCION QUE CAMBIA RESPECTO A MOSTRAR O NO LOS FILTROS -->
            <q-tabs v-model="filtrosTab" indicator-color="orange-6" class="text-grey-system h50 filtros-border z-10"
                    dense v-if="showFiltros" inline-label active-bg-color="gris-panel" align="left">
              <q-tab name="filtros" icon="las la-filter" label="Filtros" content-class="align-content-top"
                     class="tab-indicator-top" @click="cerrarFiltros()"/>
            </q-tabs>
            <!-- FINALIZA SECCION QUE CAMBIA CON RESPECTO A MOSTRAR O NO LOS FILTROS -->
          </div>
          <!-- EMPIEZA SECCION DE FILTROS -->
          <q-tab-panels v-model="filtrosTab" animated v-if="showFiltros"
                        class="bg-gris-panel border-bottom border-top w100p mt-minus-2">
            <q-tab-panel name="filtros" class="row pa-0 data-filtros">
              <div class="q-pa-md row col-12">
                <div class="col-3 q-px-lg">
                  <div class="mb4 fs-w-600">Clave / Nombre</div>
                  <q-input dense outlined v-model="filtros.busqueda" ref="second"/>
                </div>

                <div class="col-3 q-px-lg">
                  <div class="mb4 fs-w-600">Status</div>
                  <q-select :options="statusOpciones" dense emit-value multiple options-dense
                            outlined v-model="filtros.status" map-options>
                    <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
                      <q-item v-bind="itemProps" v-on="itemEvents">
                        <q-item-section>
                          <q-item-label v-html="opt.label"></q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-toggle :value="selected" @input="toggleOption(opt)"/>
                        </q-item-section>
                      </q-item>
                    </template>
                    <template #selected v-if="filtros.status.length === 0">Todos</template>
                  </q-select>
                </div>
                <div class="col-3 q-px-lg">
                  <div class="mb4 fs-w-600">Ordenar</div>
                  <q-select :options="ordenOpciones" dense emit-value options-dense
                            outlined v-model="filtros.ordenar" map-options>
                  </q-select>
                </div>
              </div>
              <div class="q-pa-md row col-12">
                <div class="col-12 text-right q-mt-lg">
                  <q-btn flat label="Cerrar" color="primary" class="q-mr-md" style="text-transform:none"
                          @click="cerrarFiltros()"/>
                  <q-btn unelevated label="aplicar filtros" color="gris-panel" text-color="grey-system"
                          class="e-border-1" @click="listar"/>
                </div>
              </div>
            </q-tab-panel>
          </q-tab-panels>
            <!-- FINALIZA SECCION DE FILTROS -->
        </q-card-section>
        <!-- COMIENZA SECCION DE TABLA -->
        <q-card-section class="table-content">
            <div class="text-right mb4 q-mr-sm" v-text="registros"/>
            <q-table :columns="columnas"
                    :data="perfiles"
                    :rows-per-page-options="[0]"
                    :hide-bottom="perfiles.length > 0"
                    separator="cell" wrap-cells bordered dense
                    table-header-class="table-content-th" table-class="table-content-rows"
                    flat no-data-label="Sin registros encontrados">
                <template #body-cell-clave="{row}">
                    <q-td class="text-center">
                        <router-link class="text-primary" :to="{name: 'perfilDetalle', params:{id:row.perfil_id}}" v-slot="{ navigate }">
                            <span class="cursor-pointer" @click="navigate" role="link">{{row.clave}}</span>
                        </router-link>
                    </q-td>
                </template>
                <template #body-cell-status="{row}">
                    <q-td class="text-center">
                        <q-badge :color="colorStatusBadge(row.status, 200)" :label="row.status === 200 ? 'Activo' : 'Eliminado'"/>
                    </q-td>
                </template>
                <template #body-cell-opciones="{row}">
                    <q-td class="text-center">
                        <q-btn icon="las la-bars" color="grey-system" flat dense :disable="row.status===300 ? true : false">
                            <q-menu anchor="bottom left" self="top left">
                                <q-list dense class="q-py-md w260">
                                    <q-item clickable v-close-popup dense :to="{name: 'perfilDetalle', params:{id: row.perfil_id}}">
                                        <q-item-section avatar class="q-pr-md mw0">
                                        <q-icon name="las la-eye" size="16px"/>
                                        </q-item-section>
                                        <q-item-section>Ver detalle</q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-btn>
                    </q-td>
                </template>
                <template #body-cell-registro="{row}">
                    <q-td class="text-center">
                        <span>{{ dateFormat(row.registro_fecha, 2, true) }} | {{row.registro_autor || "--"}}</span>
                    </q-td>
                </template>
            </q-table>
        </q-card-section>
      <!-- TERMINA SECCION DE TABLA -->
      </q-card>
    </q-scroll-area>
    <!-- TERMINA BODY GLOBAL -->

    <!-- COMIENZA MODAL ALTA -->
    <modal :model="modalAlta" @on-close="cerrarModalAlta" globalClases="w500" titulo="Agregar Perfil">
        <template #body>
            <q-form @submit.prevent="agregar()" class="row">
            <input hidden ref="inputSubmit" type="submit"/>
            <div class="row q-mb-md col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Clave</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                <div class="col-7">
                <q-input
                    dense
                    lazy-rules="ondemand"
                    outlined
                    ref="first"
                    v-model.trim="agregarPerfilObj.clave"
                    :rules="[
                        (val) => !!val || 'Campo requerido',
                        (val)=> val.length>=3 || 'No menos de 3 caracteres',
                        (val)=> val.length<=12 || 'No más de 10 caracteres'
                    ]"
                />
                </div>
            </div>
            <div class="row q-mb-md col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Nombre</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                <div class="col-7">
                <q-input
                    dense
                    lazy-rules="ondemand"
                    outlined
                    v-model.trim="agregarPerfilObj.nombre"
                    :rules="[
                        (val) => !!val || 'Campo requerido',
                        (val)=> val.length>=5 || 'No menos de 5 caracteres',
                        (val)=> val.length<=50 || 'No más de 50 caracteres'
                    ]"
                />
                </div>
            </div>
            <div class="row q-mb-md col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Descripción</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                <div class="col-7">
                <q-input
                    dense
                    lazy-rules="ondemand"
                    outlined
                    v-model.trim="agregarPerfilObj.descripcion"
                    :rules="[(val) => val.length<90 || 'No más de 90 caracteres']"
                />
                </div>
            </div>
            </q-form>
        </template>
        <template #footer>
            <q-btn
            @click="cerrarModalAlta()"
            class="q-mr-sm"
            color="primary"
            flat
            label="Cerrar ventana"
            no-caps
            outline
            unelevated
            />
            <q-btn @click="$refs.inputSubmit.click()" color="primary" unelevated label="Agregar" />
        </template>
    </modal>
    <!-- FINALIZA MODAL ALTA -->

  </q-page>
</template>

<script>
  import PerfilService from "../../Services/PerfilService";

  const perfilService = new PerfilService();
  export default {
    name: "perfilGestor",
    data() {
      return {
        breadcrumbs: [{name: "Home", to: "/home"}, {name: "Perfiles"}],
        ordenOpciones: [
          {label: 'Nombre ascendente', value: 'titulo_asc'},
          {label: 'Nombre descendente', value: 'titulo_desc'},
          {label: 'Clave ascendente', value: 'clave_asc'},
          {label: 'Clave descendente', value: 'clave_desc'},
          {label: 'Registro fecha ascendente', value: 'registro_fecha_asc'},
          {label: 'Registro fecha descendente', value: 'registro_fecha_desc'},
        ],
        statusOpciones: [{label: "Activo", value: 200}, {label: "Eliminado", value: 300}],
        perfiles: [],
        columnas: [
          {name: 'clave', label: 'Clave', field: 'clave', align: 'center',},
          {name: 'titulo', label: 'Nombre', field: 'titulo', align: 'left',},
          {name: 'descripcion', label: 'Descripción', field: 'descripcion', align: 'left',},
          {name: 'status', label: 'Status', field: 'status', align: 'center',},
          {name: 'registro', label: 'Fecha de registro', field: 'registro_fecha', align: 'center', format: val => this.dateFormat(val, 2)},
          {name: 'opciones', align: 'center', headerClasses: 'w5p'},
        ],
        agregarPerfilObj: {clave: "", nombre: "", descripcion: ""},
        showFiltros: false,
        filtros: {busqueda: "", status: [], ordenar: "registro_fecha_desc"},
        filtrosTab: "filtros",
        modalAlta: false,

      }
    },
    mounted() {
      this.inicializarFiltros(this.$route.query);
      this.listar();
    },
    computed: {
      registros() {
        if (this.perfiles.length == 1) {
          return this.perfiles.length + ' registro encontrado'
        } else if (this.perfiles.length > 1) {
          return this.perfiles.length + ' registros encontrados'

        }
      },
    },
    methods: {
      inicializarFiltros(query) {
        this.filtros = {
          busqueda: query.b ? query.b : "",
          status: this.getArrayQueryParam(query.sv, true),
          ordenar: query.o ? query.o : "registro_fecha_desc",
        };
      },
      replaceUrl() {
        let filtros = {
          b: this.filtros.busqueda,
          s: this.filtros.status,
          o: this.filtros.ordenar,
        };
        this.$router.replace({query: filtros});
      },
        listar(loader=true){
            if(loader)this.loader(true);
            this.replaceUrl();
            perfilService.listar(this.filtros)
            .then(datos=>{
                this.perfiles=datos;
            })
            .catch(error=>this.alertShow(error,'error'))
            .then(()=>{if (loader) this.loader(false);});
        },
        agregar(){
            this.loader(true);
            perfilService.agregar(this.agregarPerfilObj)
            .then(respuesta=>{
                this.alertShow(respuesta.mensaje,"exito");
                this.cerrarModalAlta();
                this.loader(false);
                this.listar();
            })
            .catch(error=>{
                this.alertShow(error,'error');
                this.loader(false);
            });
        },
        abrirModalAlta(){
            this.modalAlta = true;
            this.$nextTick(() => this.$refs.first.focus());
        },
        cerrarModalAlta(){
            this.agregarPerfilObj={clave: "", nombre: "", descripcion: ""};
            this.modalAlta=false;
        },
        cargarFiltros() {
        this.showFiltros = true;
        this.$nextTick(() => this.$refs.second.focus());
      },
      cerrarFiltros() {
        this.showFiltros = false;
      },
      limpiar() {
        this.filtros = {busqueda: "", status: [], ordenar: "registro_fecha_desc",};
        this.listar();
      },
    },

  }
</script>